<template>
    <div class="container">
        <span class="style-label">Choisir la couleur de mon tracé :</span>
        <b-form-group>
            <b-form-radio
                v-model="styleData.custom_color"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="false"
                >Couleur du balisage</b-form-radio
            >
            <b-form-radio
                v-model="styleData.custom_color"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="true"
                >Couleur customisé</b-form-radio
            >
        </b-form-group>

        <span class="style-label" v-if="styleData.custom_color == 'true'"
            >Choisir la couleur customisé :</span
        >
        <verte
            v-if="styleData.custom_color == 'true'"
            picker="square"
            model="hex"
            v-model="styleData.color_trace"
        ></verte>
    </div>
</template>
<script>
export default {
    props: ["styleData"],
};
</script>
<style>
.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}
.style-label {
    font-family: roboto-light;
    font-size: 16px;
    color: #505050;
}
</style>
