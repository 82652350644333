<template>
    <div class="container">
        <span class="style-label">Choisir la couleur des pictogrames :</span>
        <verte
            picker="square"
            model="hex"
            v-model="styleData.color_icon"
        ></verte>
        <span class="style-label">Choisir la couleur du texte :</span>
        <verte
            picker="square"
            model="hex"
            v-model="styleData.color_texte"
        ></verte>
        <span class="style-label">Choisir la couleur des formes :</span>
        <verte
            picker="square"
            model="hex"
            v-model="styleData.color_square"
        ></verte>
        <span class="style-label">Choisir la couleur de mon tracé :</span>
        <verte
            picker="square"
            model="hex"
            v-model="styleData.color_trace"
        ></verte>
        <span class="style-label"
            >Choisir la couleur principale du profil :</span
        >
        <verte
            picker="square"
            model="hex"
            v-model="styleData.color_profil_primary"
        ></verte>
        <span class="style-label"
            >Choisir la couleur secondaire du profil :</span
        >
        <verte
            picker="square"
            model="hex"
            v-model="styleData.color_profil_secondary"
        ></verte>
        <span
            class="style-label"
            v-if="!styleData.header || editionMode == true"
            >Choisir un header :</span
        >
        <b-form-file
            v-if="!styleData.header || editionMode == true"
            v-model="header"
            :state="Boolean(header)"
            placeholder="Choisir"
        >
        </b-form-file>
        <b-button v-if="!editionMode" variant="kalkin-1" v-on:click="changeMode"
            >Modifier le header</b-button
        >
    </div>
</template>
<script>
export default {
    props: ["styleData", "editionMode"],
    data: function () {
        return { header: "" };
    },
    watch: {
        header: function () {
            this.$emit("storeHeader", this.header);
            this.styleData.header = URL.createObjectURL(this.header);
        },
    },
    methods: {
        changeMode: function () {
            this.$emit("updateMode");
        },
    },
};
</script>
<style>
.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}
.style-label {
    font-family: roboto-light;
    font-size: 16px;
    color: #505050;
}
</style>
