<template>
    <div class="main">
        <Sidebar active="style" />
        <div class="main-content">
            <b-modal v-model="deleteModal" size="xl" hide-footer hide-header
                ><div class="delete-modal">
                    <span class="text-title">Suppression de style</span>
                    <span class="text-description">
                        Attention, vous allez supprimer le style
                        <span class="text-color">{{
                            selectDeleteStyle.name
                        }}</span>
                    </span>
                    <div
                        v-if="
                            selectDeleteStyle.pdfs &&
                            DataLength(selectDeleteStyle.pdfs) > 0
                        "
                        class="not-empty-section"
                    >
                        <span class="text-description"
                            >Les pdfs suivans sont associés à ce style, veuillez
                            les déplacer ou les supprimer si vous souhaitez
                            supprimer ce style.</span
                        >

                        <span
                            class="text-color item-list"
                            v-for="pdf of selectDeleteStyle.pdfs"
                            :key="pdf.id"
                        >
                            {{ pdf.name }}
                        </span>

                        <b-button
                            class="info-button button-resize"
                            v-on:click="deleteModal = false"
                            >Annuler</b-button
                        >
                    </div>
                    <div
                        v-if="
                            selectDeleteStyle.pdfs &&
                            DataLength(selectDeleteStyle.pdfs) == 0
                        "
                    >
                        <div class="modal-section-button">
                            <b-button
                                class="info-button"
                                v-on:click="deleteStyle"
                                >Valider</b-button
                            >
                            <b-button
                                class="info-button"
                                v-on:click="deleteModal = false"
                                >Annuler</b-button
                            >
                        </div>
                    </div>
                </div></b-modal
            >
            <div class="top-section">
                <div class="card new-model">
                    <div class="head-title">
                        <span class="text-title">Nouveau modèle</span>
                    </div>
                    <div class="new-model-description">
                        <div class="description">
                            <span class="text-description">
                                Un modèle permet de définir un agencement ainsi
                                que les couleurs associées pour la création de
                                vos PDF.</span
                            >
                        </div>
                        <div v-on:click="changeState(1)">
                            <b-iconstack font-scale="5">
                                <b-icon
                                    stacked
                                    icon="circle-fill"
                                    class="logo-color-secondary"
                                ></b-icon>
                                <b-icon
                                    stacked
                                    icon="plus"
                                    scale="0.5"
                                    class="logo-color-primary"
                                ></b-icon>
                            </b-iconstack>
                        </div>
                    </div>
                </div>
                <div class="card info" v-if="state != 0">
                    <div class="head-title">
                        <span class="text-title"
                            >Informations sur le modèle</span
                        >
                    </div>
                    <div class="info-group">
                        <div class="info-input">
                            <span>Nom du modèle</span
                            ><b-form-input
                                class="custom-input"
                                v-model="name"
                            />
                        </div>
                        <div class="info-input">
                            <span>Type de modèle</span
                            ><b-form-select
                                v-model="templateSelect"
                                :options="templateOptions"
                                class="custom-input"
                            ></b-form-select>
                        </div>
                    </div>
                </div>
                <div class="card button-card" v-if="state != 0">
                    <div class="button-group">
                        <b-button class="info-button" v-on:click="saveStyle"
                            >Sauvegarder le modèle</b-button
                        >
                        <b-button class="info-button" v-on:click="goCreatePdf"
                            >Créer un PDF</b-button
                        >
                    </div>
                </div>
            </div>
            <div class="bot-section">
                <div class="card models">
                    <div class="head-title">
                        <span class="text-title">Mes modèles</span
                        ><span class="text-description"
                            >Sélectionner un modèle pour le modifier</span
                        >
                    </div>
                    <perfect-scrollbar>
                        <div class="list">
                            <div
                                class="item"
                                v-for="(style, index) of styles"
                                :key="style.id"
                                :class="{ active: isActive[index] }"
                            >
                                <div class="item-left">
                                    <div>
                                        <img
                                            :src="
                                                './template' +
                                                style.template +
                                                '.png'
                                            "
                                            class="item-img"
                                        />
                                    </div>
                                    <div class="text-circle">
                                        <div class="item-text">
                                            Template {{ style.template }} -
                                            {{ style.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="logo-section">
                                    <img
                                        src="../assets/icon/pencil.svg"
                                        class="logo-color"
                                        v-on:click="selectItem(index, style.id)"
                                    />
                                    <img
                                        src="../assets/icon/trash.svg"
                                        class="logo-color"
                                        v-on:click="
                                            openDeleteModal(
                                                style.id,
                                                style.name,
                                            )
                                        "
                                    />
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
                <div class="card preview" v-if="state != 0">
                    <div class="head-title">
                        <div class="title-button">
                            <span class="text-title">Preview</span>
                            <component
                                :is="componentSelect[templateSelect].data"
                                :styleData="style"
                                :header="header"
                                v-on:getPdf="getPdf"
                                v-on:setLoading="setLoading"
                            />
                        </div>
                        <span class="text-description"
                            >Vous pouvez voir ici un aperçu du rendu basé sur le
                            modèle</span
                        >
                    </div>
                    <div class="container-preview">
                        <vue-pdf-embed
                            :source="pdf"
                            :width="zoom * 100"
                            v-if="!loading"
                        />
                        <b-spinner v-if="loading"></b-spinner>
                    </div>
                </div>
                <div class="card styles" v-if="state != 0">
                    <div class="head-title">
                        <span class="text-title">Style</span>
                    </div>
                    <perfect-scrollbar>
                        <div class="style-container">
                            <component
                                :is="componentSelect[templateSelect].style"
                                :styleData="style"
                                :header="header"
                                :editionMode="editionMode"
                                v-on:storeHeader="storeHeader"
                                v-on:updateMode="updateMode"
                            />
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Sidebar from "@/components/layouts/Sidebar.vue";
import StyleComponent1 from "@/components/style/template1/Style1.vue";
import StyleComponent2 from "@/components/style/template2/Style.vue";
import StyleComponent3 from "@/components/style/template3/Style.vue";
import StyleComponent4 from "@/components/style/template4/Style.vue";
import DataComponent1 from "@/components/style/template1/ButtonGenerate.vue";
import DataComponent2 from "@/components/style/template2/ButtonGenerate.vue";
import DataComponent3 from "@/components/style/template3/ButtonGenerate.vue";
import DataComponent4 from "@/components/style/template4/ButtonGenerate.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
    components: {
        Sidebar,
        StyleComponent1,
        StyleComponent2,
        StyleComponent3,
        StyleComponent4,
        DataComponent1,
        DataComponent2,
        DataComponent3,
        DataComponent4,
        VuePdfEmbed,
    },
    data: function () {
        return {
            state: 0,
            id: 0,
            userId: "",
            styles: [],
            templateOptions: [],
            templateSelect: "0",
            componentSelect: [
                {
                    style: "StyleComponent1",
                    preview: "PreviewComponent1",
                    data: "DataComponent1",
                },
                {
                    style: "StyleComponent2",
                    preview: "PreviewComponent2",
                    data: "DataComponent2",
                },
                {
                    style: "StyleComponent3",
                    preview: "PreviewComponent3",
                    data: "DataComponent3",
                },
                {
                    style: "StyleComponent4",
                    preview: "PreviewComponent4",
                    data: "DataComponent4",
                },
            ],
            style: {
                carto: {},
                color_texte: "rgb(12,141,123)",
                color_ico: "rgb(12,141,123)",
                header: "",
            },
            editionMode: false,
            name: "",
            header: "",
            isActive: [],
            activeStyle: {},
            zoomOptions: [3.5, 3.5, 5.5, 5.5],
            zoom: "8",
            loading: false,
            deleteModal: false,
            selectDeleteStyle: {},
        };
    },
    async beforeMount() {
        this.state = this.$route.params.state;

        if (this.$route.params.id) {
            this.id = this.$route.params.id;
        }
        this.userId = this.$store.state.user.user.id;
        this.styles = await this.$store.dispatch("style/getAll", {
            userId: this.$store.state.user.user.id,
        });
        for (let i = 0; i < this.styles.length; i++) {
            this.isActive.push(false);
        }

        let templates = this.$store.state.user.user.templates.split(",");

        //IMPORTANT ! NE PAS OUBLIER DE RAJOUTER LES TEMPLATES ICI
        let templateOptions = [
            {
                text: "Kalkin Basic",
                value: "0",
            },
            {
                text: "Kalkin Basic Paysage",
                value: "3",
            },
            {
                text: "Dômes sancy artense",
                value: "1",
            },
            {
                text: "Dômes sancy artense 2",
                value: "2",
            },
        ];
        for (let template of templateOptions) {
            for (let element of templates) {
                if (template.value == element) {
                    this.templateOptions.push(template);
                }
            }
        }
    },
    methods: {
        deleteStyle: async function () {
            await this.$store.dispatch("style/deleteStyle", {
                id: this.selectDeleteStyle.id,
            });
            this.deleteModal = false;
            this.styles = await this.$store.dispatch("style/getAll", {
                userId: this.$store.state.user.user.id,
            });
        },
        openDeleteModal: async function (id, name) {
            this.selectDeleteStyle = {};
            let pdfList = await this.$store.dispatch("pdf/getPdfFiltred", {
                userId: this.$store.state.user.user.id,
            });
            this.selectDeleteStyle.id = id;
            this.selectDeleteStyle.name = name;
            this.selectDeleteStyle.pdfs = pdfList[id];
            this.deleteModal = true;
        },
        saveStyle: async function () {
            if (this.state == 1) {
                if (this.templateSelect == 1 || this.templateSelect == 2) {
                    this.style.typeColor = {
                        RANDO: { primary: "#db195a", secondary: " #651c33" },
                        TRAIL: { primary: "#f5874f", secondary: " #6d320f" },
                        CYCLO: { primary: "#00b0d0", secondary: " #004961" },
                        VTT: { primary: "#00b598", secondary: " #01312d" },
                    };
                }
                await this.$store.dispatch("style/createStyle", {
                    data: JSON.stringify(this.style),
                    userId: this.$store.state.user.user.id,
                    template: this.templateSelect,
                    name: this.name,
                    header: this.header,
                });
                this.styles = await this.$store.dispatch("style/getAll", {
                    userId: this.$store.state.user.user.id,
                });
                for (let i = 0; i < this.styles.length; i++) {
                    this.isActive.push(false);
                }
            } else if (this.state == 2) {
                if (this.editionMode) {
                    await this.$store.dispatch("style/updateStyle", {
                        id: this.id,
                        data: JSON.stringify(this.style),
                        userId: this.$store.state.user.user.id,
                        name: this.name,
                        header: this.header,
                    });
                } else {
                    await this.$store.dispatch("style/updateStyle", {
                        id: this.id,
                        data: JSON.stringify(this.style),
                        userId: this.$store.state.user.user.id,
                        name: this.name,
                    });
                }
                this.editionMode = false;
            }

            // this.$router.push({ name: "Home" });
        },
        storeHeader: function (data) {
            this.header = data;
        },
        changeState: function (state) {
            this.state = state;
            if (state == 0 || state == 1) {
                this.isActive = [];
                for (let i = 0; i < this.styles.length; i++) {
                    this.isActive.push(false);
                }
            }
        },
        selectItem: async function (index, id) {
            this.isActive = [];
            for (let i = 0; i < this.styles.length; i++) {
                this.isActive.push(false);
            }
            this.isActive[index] = true;
            this.changeState(2);
            let styleData = await this.$store.dispatch("style/getStyle", {
                id: id,
            });

            this.id = styleData.id;
            this.style = JSON.parse(styleData.data);
            this.name = styleData.name;
            this.templateSelect = styleData.template;
        },
        updateMode: function () {
            this.editionMode = true;
        },
        getPdf: function (pdf) {
            this.zoom = this.zoomOptions[this.templateSelect];

            this.pdf = "data:application/pdf;base64," + pdf;

            this.setLoading();
        },

        setLoading: function () {
            this.loading = !this.loading;
        },
        DataLength: function (data) {
            return data.length;
        },
        goCreatePdf: function () {
            this.$router.push({
                name: "Pdf",
            });
        },
    },
};
</script>
<style scoped>
.ps {
    height: 500px;
}

.main {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    height: 100%;
    background-color: #e5e5e5;
}
.main-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding-left: 30px;
    padding-top: 30px;
    width: 100%;
}
.left-card {
    flex-grow: 2;
}
.head-title {
    display: flex;
    flex-direction: column;
    margin-left: 31px;
    margin-top: 23px;
}
.text-title {
    font-family: roboto-bold;
    font-size: 24px;
    color: #394949;
}
.text-description {
    font-family: roboto-light;
    font-size: 16px;
    color: #a8bbb5;
    margin-top: 16px;
}
.item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 15px rgba(182, 182, 182, 0.25);
    border-radius: 16px;
    width: 90%;
    height: 108px;
    padding-left: 16px;
    padding-right: 10px;
    cursor: pointer;
}
.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
}
.item-left {
    display: flex;
    flex-direction: row;
}
.item-img {
    width: 80px;
}
.item-text {
    font-family: roboto-medium;
    color: #00817b;
    font-size: 16px;
}
.text-color {
    color: #00817b;
}
.text-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;

    margin-left: 16px;
}
.list-circle {
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.card {
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.logo-color {
    color: #00817b;
    cursor: pointer;
}

.logo-color-secondary {
    color: #d6ebe4;
    width: 56px;
}
.logo-color-primary {
    color: #00817b;
}
.logo-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}
.new-model-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 19px;
    height: 20%;
}
.bot-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 70%;
    gap: 19px;
}
.new-model {
    width: 512px;
}
.models {
    width: 512px;
}
.info {
    width: 693px;
}
.preview {
    width: 693px;
}
.styles {
    width: 329px;
}
.description {
    width: 65%;
}
.button-card {
    width: 329px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.button-group {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.info-button {
    background: #00817b;
    border-radius: 16px;
}
.info-group {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.info-input {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 70%;
}
.custom-input {
    background: #ededed;
    border: 1px solid #394949;
    border-radius: 49px;
    padding-left: 20px;
    width: 60%;
    height: 90%;
}
.item.active {
    background: #d6ebe4;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 15px rgba(182, 182, 182, 0.25);
    border-radius: 16px;
}
.container-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    margin-right: 28px;
    margin-left: 28px;
    background: #eae9e9;
}
.title-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 25px;
}
.delete-modal {
    display: flex;
    flex-direction: column;

    align-items: center;
}
.item-list {
    font-size: 20px;
}
.not-empty-section {
    display: flex;
    flex-direction: column;

    align-items: center;
}
.button-resize {
    width: 10%;
    margin-top: 20px;
}
.modal-section-button {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
}
</style>
