<template>
    <div class="container">
        <span class="style-label">Choisir la couleur de texte :</span>
        <verte
            picker="square"
            model="hex"
            v-model="styleData.color_secondary"
        ></verte>
        <span class="style-label"
            ><b
                >Choisir la couleur du modèle pour chaque type de fiche :</b
            ></span
        >
        <span class="style-label">Rando-pédestre :</span>
        <verte picker="square" model="hex" v-model="styleData.rando"></verte>
        <span class="style-label">VTT :</span>
        <verte picker="square" model="hex" v-model="styleData.vtt"></verte>
        <span class="style-label">Cyclotourisme :</span>
        <verte picker="square" model="hex" v-model="styleData.cyclo"></verte>
        <span class="style-label">Trail :</span>
        <verte picker="square" model="hex" v-model="styleData.trail"></verte>
        <span class="style-label">Choisir la couleur de mon tracé :</span>
        <b-form-group>
            <b-form-radio
                v-model="styleData.custom_color"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="false"
                >Couleur du balisage</b-form-radio
            >
            <b-form-radio
                v-model="styleData.custom_color"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="true"
                >Couleur customisé</b-form-radio
            >
        </b-form-group>

        <span class="style-label" v-if="styleData.custom_color == 'true'"
            >Choisir la couleur customisé :</span
        >
        <verte
            v-if="styleData.custom_color == 'true'"
            picker="square"
            model="hex"
            v-model="styleData.color_trace"
        ></verte>
        <span class="style-label"
            >Choisir la couleur principale du profil :</span
        >
        <verte
            picker="square"
            model="hex"
            v-model="styleData.color_profil_primary"
        ></verte>
        <span class="style-label"
            >Choisir la couleur secondaire du profil :</span
        >
        <verte
            picker="square"
            model="hex"
            v-model="styleData.color_profil_secondary"
        ></verte>
        <span class="style-label">Choisir la couleur des formes en fond :</span>
        <verte
            picker="square"
            model="hex"
            v-model="styleData.color_square"
        ></verte>
        <span
            class="style-label"
            v-if="!styleData.header || editionMode == true"
            >Choisir un logo :</span
        >
        <b-form-file
            v-if="!styleData.header || editionMode == true"
            v-model="header"
            :state="Boolean(header)"
            placeholder="Choisir"
        >
        </b-form-file>
        <b-button v-if="!editionMode" variant="kalkin-1" v-on:click="changeMode"
            >Modifier le logo</b-button
        >
        <span class="style-label">Texte 1 :</span>
        <b-form-input
            v-model="styleData.text_1"
            placeholder="Retrouvez tous nos itinéraires sur..."
        ></b-form-input>
        <span class="style-label">Texte 2 :</span>
        <b-form-input
            v-model="styleData.text_2"
            placeholder="Office de tourisme..."
        ></b-form-input>
        <span class="style-label">Texte 3 :</span>
        <b-form-input
            v-model="styleData.text_3"
            placeholder="Slogan par exemple"
        >
        </b-form-input>
    </div>
</template>
<script>
export default {
    props: ["styleData", "editionMode"],
    data: function () {
        return { header: "" };
    },
    watch: {
        header: function () {
            this.$emit("storeHeader", this.header);
            this.styleData.header = URL.createObjectURL(this.header);
        },
    },
    methods: {
        changeMode: function () {
            this.$emit("updateMode");
        },
    },
};
</script>
<style>
.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}
.style-label {
    font-family: roboto-light;
    font-size: 16px;
    color: #505050;
}
</style>
