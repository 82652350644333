<template>
    <b-button v-on:click="previewPdf" class="button-custom"
        >Actualiser</b-button
    >
</template>
<script>
import pdfData from "../../../assets/data/pdfData.json";
export default {
    props: ["styleData", "header"],
    data: function () {
        return {
            typeColor: {
                RANDO: { primary: "#db195a", secondary: " #651c33" },
                TRAIL: { primary: "#f5874f", secondary: " #6d320f" },
                CYCLO: { primary: "#00b0d0", secondary: " #004961" },
                VTT: { primary: "#00b598", secondary: " #01312d" },
            },
        };
    },
    methods: {
        previewPdf: async function () {
            this.$emit("setLoading");
            await this.generateMap();
            let res = await this.generatePdf();

            this.$emit("getPdf", res);
        },
        generateStyle: async function () {
            await this.$store.dispatch("style/generateStylePreview", {
                userId: this.$store.state.user.user.id,
                header: this.header,
            });
        },
        generateMap: async function () {
            if (this.styleData.custom_color == "false") {
                this.styleData.color_trace = "#283583";
            }
            await this.$store.dispatch("pdf/generateMapPreview", {
                userId: this.$store.state.user.user.id,
                template: 2,
                profilSecondaryColor: this.typeColor["TRAIL"].secondary,
                profilPrimaryColor: this.typeColor["TRAIL"].primary,
                traceColor: this.styleData.color_trace,
            });
        },
        generatePdf: async function () {
            let res = await this.$store.dispatch("pdf/generatePreview", {
                items: pdfData,
                userId: this.$store.state.user.user.id,
                template: 2,
            });
            return res;
        },
    },
};
</script>
<style scoped>
.button-custom {
    background: #00817b;
    border-radius: 16px;
}
</style>
